.repo-stats {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  color: rgb(106, 115, 125);
}

.repo-left-stat {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.language-color {
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
  border-radius: 100%;
}

.repo-left-stat span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.75rem;
}

.repo-name-div {
  display: flex;
  align-items: center;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-name {
  white-space: nowrap;
  line-height: 1.1;
  text-overflow: ellipsis;
  color: #e3405f;
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  margin: 0px;
}

.repo-star-svg {
  margin-right: 0.3srem;
}

.repo-description {
  word-wrap: break-word;
  text-align: left;
  font-size: 17px;
  font-family: "Google Sans Regular";
}

.repo-details {
  display: flex;
  flex-direction: row;
}

.repo-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

@media (max-width: 768px) {
  .repo-name {
    font-size: 16px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }
}
.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.3s);
  border-radius: 40px;
  width: 70px;
  text-align: center;
  transition: 0.3s all ease-in-out !important;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
  transition: 0.3s all ease-in-out !important;
}

.button__bar li button {
  border: none;
  background: #dce4f2 !important;
  transition: 0.3s all ease-in-out !important;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  background-color: #e3405f !important;
  transition: 0.3s all ease-in-out !important;
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: #e3405f !important;
  outline: 0;
}

.slick-prev {
  left: -40px !important;

  transition: 0.3s all ease-in-out !important;
}

.slick-next {
  right: -22px !important;

  transition: 0.3s all ease-in-out !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
  transition: 0.3s all ease-in-out !important;
}

.react-responsive-modal-modal {
  overflow: hidden !important;
  border-radius: 10px !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.z-999 {
  z-index: 999;
}
